import { useSelector } from 'react-redux';

import { Container, Group, Paper, Space, Stack, Stepper, Text, Title } from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import { IconCircleCheck } from '@tabler/icons-react';

import { selectExtras, selectPartecipants, selectRooms } from '@slices/checkout.slice';

import Ensurances from './Ensurances';
import Extras from './Extras';
import Partecipants from './Partecipants';
import PartecipantsDetails from './PartecipantsDetails';
import Rooms from './Rooms';
import Summary from './Summary';

export default function DemoCheckout() {
  // ==========================================================================
  // State
  // ==========================================================================
  const [active, { set, increment }] = useCounter(0);

  const partecipants = useSelector(selectPartecipants);
  const rooms = useSelector(selectRooms);
  const extras = useSelector(selectExtras);

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Container size="80rem" py="xl" h="100dvh">
      <Stack justify="space-between" h="100%">
        <Stack gap={0} flex={1}>
          <Title>Weekend in barca alle Isole Egadi</Title>
          <Title order={3} c="dimmed">
            12 dicembre - 16 dicembre
          </Title>

          <Stepper
            active={active}
            onStepClick={set}
            allowNextStepsSelect={false}
            mt="xl"
            flex={1}
            styles={{
              root: { display: 'flex', flexDirection: 'column' },
              content: { flexGrow: 1 },
            }}
          >
            <Stepper.Step label="Partecipanti">
              <Paper withBorder p="xl" h="100%">
                <Partecipants onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Camere">
              <Paper withBorder p="xl" h="100%">
                <Rooms onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Extra">
              <Paper withBorder p="xl" h="100%">
                <Extras onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Assicurazioni">
              <Paper withBorder p="xl" h="100%">
                <Ensurances onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Dettagli partecipanti">
              <Paper withBorder p="xl" h="100%">
                <PartecipantsDetails onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Riepilogo e pagamento">
              <Paper withBorder p="xl" h="100%">
                <Summary onConfirm={increment} />
              </Paper>
            </Stepper.Step>
            <Stepper.Completed>
              <Stack mt="10rem" align="center">
                <IconCircleCheck size={100} color="green" />
                <Text size="xl" fw="bold">
                  Prenotazione completata!
                </Text>
                <Text>
                  Riceverai una email di conferma. Se non la trovi controlla la
                  cartella spam.
                </Text>
              </Stack>
            </Stepper.Completed>
          </Stepper>
        </Stack>

        {active < 4 && (
          <Paper bg="brand" c="white" p="lg">
            <Group justify="space-between">
              <Stack gap={0}>
                {active >= 1 && (
                  <Text>Partecipanti: {partecipants.length}</Text>
                )}
                {active >= 2 && <Text>Camere: {rooms.length}</Text>}
                {active >= 3 && extras.aperitivo.length > 0 && (
                  <Text>Extra: Aperitivo (x{extras.aperitivo.length})</Text>
                )}
              </Stack>

              <Group justify="end" align="center">
                <Text>TOTALE</Text>
                <Text fw="bold" size="xl">
                  € 250.00
                </Text>
              </Group>
            </Group>
          </Paper>
        )}
        <Space h="xl" />
      </Stack>
    </Container>
  );
}
