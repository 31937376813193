import { useDispatch, useSelector } from 'react-redux';

import { Button, Chip, Group, Paper, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { selectExtras, selectPartecipants, setExtras } from '@slices/checkout.slice';

interface ExtraProps {
  onConfirm: () => void;
}

export default function Extras({ onConfirm }: ExtraProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);
  const extras = useSelector(selectExtras);

  // ==========================================================================
  // Form
  // ==========================================================================
  const extrasForm = useForm({
    mode: 'uncontrolled',
    initialValues: structuredClone(extras),
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof extrasForm.values) => {
    dispatch(setExtras(values));

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form onSubmit={extrasForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Stack flex={1}>
          <Title order={3}>Extra disponibili</Title>

          {/* <Paper withBorder p="md">
                        <Group justify="space-between">
                          <Text>Regalo di benvenuto</Text>
                          <Group>
                            <Text>€ 20.00</Text>
                            <Button
                              onClick={() => {
                                if (extrasForm.getValues().welcome === 1) {
                                  extrasForm.setFieldValue('welcome', 0);
                                } else {
                                  extrasForm.setFieldValue('welcome', 1);
                                }
                              }}
                              leftSection={
                                extrasForm.getValues().welcome === 1 ? (
                                  <IconCheck />
                                ) : undefined
                              }
                              color={
                                extrasForm.getValues().welcome === 1
                                  ? 'green'
                                  : undefined
                              }
                            >
                              {extrasForm.getValues().welcome === 1
                                ? 'Selezionato'
                                : 'Seleziona'}
                            </Button>
                          </Group>
                        </Group>
                      </Paper> */}

          <Paper withBorder p="md">
            <Group justify="space-between">
              <Text fw="bold">Aperitivo</Text>
              <Group>
                <Text>€ 10.00</Text>
                <Text fs="italic" size="sm" c="dimmed">
                  / persona
                </Text>

                <Stack gap="xs" align="end">
                  {partecipants.map((partecipant) => (
                    <Chip
                      key={partecipant.key}
                      checked={extrasForm
                        .getValues()
                        .aperitivo.includes(partecipant.key)}
                      onChange={(checked) => {
                        if (checked) {
                          extrasForm.insertListItem(
                            'aperitivo',
                            partecipant.key,
                          );
                        } else {
                          extrasForm.removeListItem(
                            'aperitivo',
                            extrasForm
                              .getValues()
                              .aperitivo.indexOf(partecipant.key),
                          );
                        }
                      }}
                    >
                      {partecipant.firstName} {partecipant.lastName}
                    </Chip>
                  ))}
                </Stack>
              </Group>
            </Group>
          </Paper>

          {/* <Title order={3}>Assicurazioni</Title>
                      <Text>TODO</Text> */}
        </Stack>

        <Group justify="end" align="end">
          <Button size="lg" type="submit">
            PROSEGUI
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
