import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Chip, Group, Select, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import { CheckoutRoom } from '@interfaces/checkout.interface';

import {
  selectPartecipants,
  selectRooms,
  setRooms,
} from '@slices/checkout.slice';

interface RoomsProps {
  onConfirm: () => void;
}

export default function Rooms({ onConfirm }: RoomsProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const rooms = useSelector(selectRooms);
  const partecipants = useSelector(selectPartecipants);

  // TODO: get available rooms

  // ==========================================================================
  // Form
  // ==========================================================================
  const roomsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      rooms: structuredClone(rooms),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof roomsForm.values) => {
    dispatch(setRooms(values.rooms));

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form onSubmit={roomsForm.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack h="100%">
        <Group justify="space-between" align="start" h="100%">
          <Stack flex={1} align="start">
            <Button
              leftSection={<IconPlus />}
              onClick={() => {
                roomsForm.insertListItem('rooms', {
                  key: randomId(),
                  type: null,
                  partecipantsIds: [],
                });
              }}
            >
              Aggiungi camera
            </Button>

            {roomsForm.getValues().rooms.map((room, index) => (
              <Group key={room.key} gap="xl">
                <Stack>
                  <Select
                    label="Tipologia camera"
                    placeholder="Seleziona tipologia"
                    data={['Singola', 'Doppia']}
                    required
                    allowDeselect={false}
                    key={roomsForm.key(`rooms.${index}.type`)}
                    {...roomsForm.getInputProps(`rooms.${index}.type`)}
                    onChange={(value) => {
                      roomsForm.setFieldValue(
                        `rooms.${index}.partecipants`,
                        [],
                      );
                      roomsForm.setFieldValue(`rooms.${index}.type`, value);
                    }}
                  />
                  <Button
                    color="red"
                    leftSection={<IconTrash />}
                    onClick={() => {
                      roomsForm.removeListItem('rooms', index);
                    }}
                  >
                    Rimuovi camera
                  </Button>
                </Stack>

                {room.type && (
                  <Stack gap="xs">
                    <Text>
                      Seleziona {getQuantityFromRoom(room)} persona
                      {getQuantityFromRoom(room) > 1 ? 'e' : 'a'}
                    </Text>
                    {partecipants.map((partecipant) => (
                      <Chip
                        key={room.key + partecipant.key}
                        checked={room.partecipantsIds.includes(partecipant.key)}
                        onChange={(checked) => {
                          if (checked) {
                            roomsForm.insertListItem(
                              `rooms.${index}.partecipantsIds`,
                              partecipant.key,
                            );
                          } else {
                            roomsForm.removeListItem(
                              `rooms.${index}.partecipantsIds`,
                              room.partecipantsIds.indexOf(partecipant.key),
                            );
                          }
                        }}
                        disabled={
                          (!room.partecipantsIds.includes(partecipant.key) &&
                            room.partecipantsIds.length >=
                              getQuantityFromRoom(room)) ||
                          !!roomsForm
                            .getValues()
                            .rooms.find(
                              (r) =>
                                r.key !== room.key &&
                                r.partecipantsIds.includes(partecipant.key),
                            )
                        }
                      >
                        {partecipant.firstName} {partecipant.lastName}
                      </Chip>
                    ))}
                  </Stack>
                )}
              </Group>
            ))}
          </Stack>

          <Stack>
            <Text>
              Partecipanti assegnati{' '}
              {roomsForm
                .getValues()
                .rooms.reduce(
                  (acc, room) => acc + room.partecipantsIds.length,
                  0,
                )}{' '}
              / {partecipants.length}
            </Text>
          </Stack>
        </Group>

        <Group justify="end" align="end">
          {roomsForm.getValues().rooms.length === 0 ? (
            <Alert color="red">Aggiungi almeno una camera per continuare</Alert>
          ) : (
            roomsForm
              .getValues()
              .rooms.reduce(
                (acc, room) => acc + room.partecipantsIds.length,
                0,
              ) !== partecipants.length && (
              <Alert color="red">
                Tutti i partecipanti devono essere assegnati a una camera
              </Alert>
            )
          )}

          <Button
            size="lg"
            disabled={
              roomsForm.getValues().rooms.length === 0 ||
              roomsForm
                .getValues()
                .rooms.reduce(
                  (acc, room) => acc + room.partecipantsIds.length,
                  0,
                ) !== partecipants.length
            }
            type="submit"
          >
            PROSEGUI
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

function getQuantityFromRoom(room: CheckoutRoom) {
  switch (room.type) {
    case 'single':
      return 1;
    case 'double':
    case 'doubleShareable':
    case 'twin':
    case 'dus':
    case 'superior':
      return 2;
    case 'triple':
      return 3;
    case 'quadruple':
      return 4;
    case 'spot':
      return room.roomSpots ?? 1;
  }
}
