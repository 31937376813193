import { useSelector } from 'react-redux';

import {
  Alert,
  Button,
  Divider,
  Grid,
  Group,
  Paper,
  Radio,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import {
  selectEnsurances,
  selectExtras,
  selectIsCompany,
  selectPartecipants,
  selectRooms,
} from '@slices/checkout.slice';

interface SummaryProps {
  onConfirm: () => void;
}

export default function Summary({ onConfirm }: SummaryProps) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);
  const rooms = useSelector(selectRooms);

  const isCompany = useSelector(selectIsCompany);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleConfirm = () => {
    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Stack h="100%">
      <Grid flex={1} gutter="xl">
        <Grid.Col span={{ md: 8 }}>
          <Stack>
            <Title order={3}>Partecipanti</Title>
            <Stack>
              {partecipants.find((p) => p.isHolder) && (
                <>
                  <Text fw="bold">
                    {partecipants.find((p) => p.isHolder)!.firstName}{' '}
                    {partecipants.find((p) => p.isHolder)!.lastName} -
                    Intestatario prenotazione
                  </Text>
                  <PartecipantInfo
                    partecipant={partecipants.find((p) => p.isHolder)!}
                  />
                </>
              )}

              {partecipants
                .filter((p) => !p.isHolder)
                .map((partecipant) => (
                  <>
                    <Text key={partecipant.key} fw="bold">
                      {partecipant.firstName} {partecipant.lastName}
                    </Text>
                    <PartecipantInfo partecipant={partecipant} />
                  </>
                ))}
            </Stack>
            <Alert icon={<IconAlertTriangle />}>
              I dati mancanti dei partecipanti devono essere compilati entro 24h
              dalla prenotazione. Riceverai le istruzioni per compilarli via
              email.
            </Alert>

            <Divider my="sm" />
            <Title order={3}>Camere</Title>
            {rooms.map((room) => (
              <Group key={room.key}>
                <Text>{room.type}</Text>
                <Text>{room.roomSpots}</Text>
              </Group>
            ))}

            {isCompany && (
              <>
                <Divider my="sm" />
                <Title order={3}>Indirizzo di fatturazione</Title>

                <Text>Inserisci l'indirizzo di fatturazione qui sotto.</Text>
                <Grid>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="Nominativo / Ragione sociale"
                      placeholder="Nominativo"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="P.IVA / CF"
                      placeholder="P.IVA / CF"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      type="email"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="Telefono"
                      placeholder="Telefono"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput label="PEC" placeholder="PEC" type="email" />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput label="SDI" placeholder="SDI" />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput label="Nazione" placeholder="Nazione" required />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="Provincia"
                      placeholder="Provincia"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput label="Città" placeholder="Città" required />
                  </Grid.Col>

                  <Grid.Col span={{ md: 4 }}>
                    <TextInput label="CAP" placeholder="CAP" required />
                  </Grid.Col>
                  <Grid.Col span={{ md: 4 }}>
                    <TextInput
                      label="Indirizzo"
                      placeholder="Indirizzo"
                      required
                    />
                  </Grid.Col>
                </Grid>
              </>
            )}

            <Divider my="sm" />
            <Title order={3}>Modalità di pagamento</Title>

            <Radio.Group>
              <Stack>
                <Radio
                  value="nexi"
                  label="Carta di credito / debito"
                  description="Pagamento tramite gateway di pagamento Nexi"
                />
                <Radio
                  value="paypal"
                  label="Paypal"
                  description="Pagamento tramite gateway di pagamento Paypal"
                />
              </Stack>
            </Radio.Group>
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <Paper bg="brand" c="white" p="lg" pos="sticky" top="2rem">
            <Stack gap={0} align="end">
              <Text fw="bold" size="xl" ta="right">
                Weekend in barca alle Isole Egadi
              </Text>
              <Text c="#ddd" ta="right">
                12 dicembre - 16 dicembre
              </Text>

              <Text mt="md" mb="xs">
                Hai un codice sconto? Inseriscilo qui:
              </Text>
              <TextInput placeholder="Codice sconto" ta="right" />

              <Table
                mt="lg"
                withRowBorders={false}
                cellPadding={0}
                cellSpacing={0}
                maw="15rem"
              >
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td align="right">
                      <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                        Costo vacanza
                      </Text>
                    </Table.Td>
                    <Table.Td align="right">
                      <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                        € 250.00
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td align="right">
                      <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                        Costo extra
                      </Text>
                    </Table.Td>
                    <Table.Td align="right">
                      <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                        € 250.00
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td align="right">
                      <Text fs="italic" style={{ whiteSpace: 'nowrap' }}>
                        Costo assicurazioni
                      </Text>
                    </Table.Td>
                    <Table.Td align="right">
                      <Text fw="bold" style={{ whiteSpace: 'nowrap' }}>
                        € 250.00
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr style={{ borderTop: '1px solid #E9ECEF' }}>
                    <Table.Td align="right">
                      <Text fs="italic">TOTALE</Text>
                    </Table.Td>
                    <Table.Td align="right">
                      <Text fw="bold">€ 250.00</Text>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>

              <Button
                variant="white"
                mt="xl"
                size="lg"
                w="100%"
                onClick={handleConfirm}
              >
                CONFERMA E PAGA
              </Button>
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

function PartecipantInfo({
  partecipant,
}: {
  partecipant: CheckoutPartecipant;
}) {
  const extras = useSelector(selectExtras);
  const ensurances = useSelector(selectEnsurances);

  return (
    <>
      <Grid gutter="xs">
        <Grid.Col span={6}>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              CF:
            </Text>{' '}
            {partecipant.taxCode || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Sesso:
            </Text>{' '}
            {partecipant.gender === 'female' ? 'Femmina' : 'Maschio'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Data di nascita:
            </Text>{' '}
            {partecipant.birthDate
              ? new Date(partecipant.birthDate).toLocaleDateString()
              : '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Città di nascita:
            </Text>{' '}
            {partecipant.birthCity || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Altezza:
            </Text>{' '}
            {partecipant.height ? `${partecipant.height} cm` : '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Nazione:
            </Text>{' '}
            {partecipant.country || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Provincia:
            </Text>{' '}
            {partecipant.province || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Città:
            </Text>{' '}
            {partecipant.city || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              CAP:
            </Text>{' '}
            {partecipant.postalCode || '-'}
          </Text>
          <Text size="sm" c="dimmed">
            <Text span fw="bold">
              Indirizzo:
            </Text>{' '}
            {partecipant.address || '-'}
          </Text>
        </Grid.Col>
        {partecipant.notes && (
          <Grid.Col span={12}>
            <Text span size="sm" c="dimmed" fw="bold">
              Note / allergie / altre informazioni:
            </Text>{' '}
            <Text size="sm" c="dimmed">
              {partecipant.notes}
            </Text>
          </Grid.Col>
        )}
      </Grid>

      <Table
        w="min-content"
        cellPadding={0}
        cellSpacing={0}
        withRowBorders={false}
        styles={{ td: { padding: 0 } }}
      >
        <Table.Tbody>
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              Costo vacanza
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              € 125.00
            </Table.Td>
          </Table.Tr>
          {extras.aperitivo.includes(partecipant.key) && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                Aperitivo
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € 10.00
              </Table.Td>
            </Table.Tr>
          )}
          {ensurances.ensurance1.includes(partecipant.key) && (
            <Table.Tr>
              <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
                Assicurazione 1
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
                € 50.00
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Td fs="italic" style={{ whiteSpace: 'nowrap' }}>
              TOTALE
            </Table.Td>
            <Table.Td style={{ whiteSpace: 'nowrap', paddingLeft: '1rem' }}>
              € 125.00
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Divider />
    </>
  );
}
