import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CheckoutEnsurances,
  CheckoutExtras,
  CheckoutPartecipant,
  CheckoutRoom
} from '@interfaces/checkout.interface';
import { HolidayVariation, HolidayWithVariations } from '@interfaces/holidays.interface';

import { RootState } from '../store';

interface CheckoutState {
  holiday: HolidayWithVariations | null;
  holidayVariation: HolidayVariation | null;
  partecipants: CheckoutPartecipant[];
  rooms: CheckoutRoom[];
  extras: CheckoutExtras; // TODO: temp
  ensurances: CheckoutEnsurances; // TODO: temp
  isCompany: boolean;
}

const initialState: CheckoutState = {
  holiday: null,
  holidayVariation: null,
  partecipants: [],
  rooms: [],
  extras: {
    welcome: 0,
    aperitivo: [],
  },
  ensurances: {
    ensurance1: [],
    ensurance2: [],
  },
  isCompany: false,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setHoliday(state, action: PayloadAction<HolidayWithVariations | null>) {
      state.holiday = action.payload;
    },
    setHolidayVariation(state, action: PayloadAction<HolidayVariation | null>) {
      state.holidayVariation = action.payload;
    },
    setPartecipants(state, action: PayloadAction<CheckoutPartecipant[]>) {
      state.partecipants = action.payload;
    },
    setRooms(state, action: PayloadAction<CheckoutRoom[]>) {
      state.rooms = action.payload;
    },
    setExtras(state, action: PayloadAction<CheckoutExtras>) {
      state.extras = action.payload;
    },
    setEnsurances(state, action: PayloadAction<CheckoutEnsurances>) {
      state.ensurances = action.payload;
    },
    setIsCompany(state, action: PayloadAction<boolean>) {
      state.isCompany = action.payload;
    },
  },
});

export const {
  setHoliday,
  setHolidayVariation,
  setPartecipants,
  setRooms,
  setExtras,
  setEnsurances,
  setIsCompany,
} = slice.actions;

export const selectHoliday = (state: RootState) => state.checkout.holiday;
export const selectHolidayVariation = (state: RootState) =>
  state.checkout.holidayVariation;
export const selectPartecipants = (state: RootState) =>
  state.checkout.partecipants;
export const selectRooms = (state: RootState) => state.checkout.rooms;
export const selectExtras = (state: RootState) => state.checkout.extras;
export const selectEnsurances = (state: RootState) => state.checkout.ensurances;
export const selectIsCompany = (state: RootState) => state.checkout.isCompany;

export default slice.reducer;
