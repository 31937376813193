import { useDispatch, useSelector } from 'react-redux';

import { Button, Chip, Group, Paper, Stack, Text, Title, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';

import { selectEnsurances, selectPartecipants, setEnsurances } from '@slices/checkout.slice';

interface EnsurancesProps {
  onConfirm: () => void;
}

export default function Ensurances({ onConfirm }: EnsurancesProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);
  const ensurances = useSelector(selectEnsurances);

  // ==========================================================================
  // Form
  // ==========================================================================
  const ensurancesForm = useForm({
    mode: 'uncontrolled',
    initialValues: structuredClone(ensurances),
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof ensurancesForm.values) => {
    dispatch(setEnsurances(values));

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={ensurancesForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Stack flex={1}>
          <Title order={3}>Assicurazioni disponibili</Title>

          <Paper withBorder p="md">
            <Group justify="space-between">
              <div>
                <Text fw="bold">Assicurazione 1</Text>
                <Text c="dimmed">Descrizione del pacchetto assicurativo.</Text>
              </div>
              <Group>
                <Text>€ 50.00</Text>
                <Text fs="italic" size="sm" c="dimmed">
                  / persona
                </Text>

                <Stack gap="xs" align="end">
                  {partecipants.map((partecipant) => (
                    <Tooltip
                      key={partecipant.key}
                      label={'Inserisci la data di nascita nel passaggio 1'}
                      styles={{
                        tooltip: {
                          display: partecipant.birthDate ? 'none' : undefined,
                        },
                      }}
                    >
                      <div>
                        <Chip
                          checked={ensurancesForm
                            .getValues()
                            .ensurance1.includes(partecipant.key)}
                          onChange={(checked) => {
                            if (checked) {
                              ensurancesForm.insertListItem(
                                'ensurance1',
                                partecipant.key,
                              );
                            } else {
                              ensurancesForm.removeListItem(
                                'ensurance1',
                                ensurancesForm
                                  .getValues()
                                  .ensurance1.indexOf(partecipant.key),
                              );
                            }
                          }}
                          disabled={!partecipant.birthDate}
                        >
                          {partecipant.firstName} {partecipant.lastName}
                        </Chip>
                      </div>
                    </Tooltip>
                  ))}
                </Stack>
              </Group>
            </Group>
          </Paper>
        </Stack>

        <Group justify="end" align="end">
          <Button size="lg" type="submit">
            PROSEGUI
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
