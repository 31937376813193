import parser from 'any-date-parser';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alert,
  Button,
  Divider,
  Grid,
  Group,
  NumberInput,
  Stack,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import { selectPartecipants, setPartecipants } from '@slices/checkout.slice';

interface PartecipantsDetailsProps {
  onConfirm: () => void;
}

export default function PartecipantsDetails({
  onConfirm,
}: PartecipantsDetailsProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);

  // ==========================================================================
  // Form
  // ==========================================================================
  const partecipantsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: structuredClone(
        partecipants.map((p) => ({
          ...p,
          birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
        })),
      ),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof partecipantsForm.values) => {
    dispatch(
      setPartecipants(
        values.partecipants.map((p) => ({
          ...p,
          birthDate: p.birthDate?.toISOString(),
        })),
      ),
    );

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={partecipantsForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Stack flex={1}>
          <Title order={3}>Dati partecipanti</Title>

          {partecipantsForm
            .getValues()
            .partecipants.find((p) => p.isHolder) && (
            <PartecipantsInputs
              key="holder-key"
              index={partecipantsForm
                .getValues()
                .partecipants.findIndex((p) => p.isHolder)}
              partecipantsForm={partecipantsForm}
              partecipant={
                partecipantsForm
                  .getValues()
                  .partecipants.find((p) => p.isHolder)!
              }
              required
            />
          )}

          <Alert my="md">
            <Group justify="space-between">
              Non hai i dati di tutti i partecipanti? Puoi inserirli
              successivamente, entro 24h dalla prenotazione
              <Button variant="outline">Inserisci successivamente</Button>
            </Group>
          </Alert>

          {partecipantsForm
            .getValues()
            .partecipants.map((partecipant, index) =>
              partecipant.isHolder ? null : (
                <PartecipantsInputs
                  key={partecipant.key}
                  index={index}
                  partecipantsForm={partecipantsForm}
                  partecipant={partecipant}
                />
              ),
            )}
        </Stack>

        <Group justify="end" align="end">
          <Button size="lg" type="submit">
            PROSEGUI
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

function PartecipantsInputs({
  index,
  partecipantsForm,
  partecipant,
  required = false,
}: {
  index: number;
  partecipantsForm: UseFormReturnType<any>;
  partecipant: Omit<CheckoutPartecipant, 'birthDate'>;
  required?: boolean;
}) {
  const { i18n } = useTranslation();

  return (
    <>
      <Divider
        label={`${partecipant.firstName} ${partecipant.lastName} (${partecipant.gender === 'female' ? 'F' : 'M'}) ${partecipant.isHolder ? '- Intestatario prenotazione' : ''}`}
        labelPosition="left"
        fw="bold"
        styles={{
          label: {
            fontSize: '1.1rem',
            color: 'var(--mantine-color-black)',
          },
        }}
      />

      <Grid>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Codice fiscale"
            placeholder="Codice fiscale"
            required={required}
            {...partecipantsForm.getInputProps(`partecipants.${index}.taxCode`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <DateInput
            label="Data di nascita"
            placeholder="Data di nascita"
            required={required}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthDate`,
            )}
            dateParser={(value) => parser.fromString(value, i18n.language)}
            valueFormat="DD/MM/YYYY"
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Città di nascita"
            placeholder="Città di nascita"
            required={required}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.birthCity`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <NumberInput
            label="Altezza (cm)"
            placeholder="Altezza (cm)"
            min={50}
            required={required}
            {...partecipantsForm.getInputProps(`partecipants.${index}.height`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Nazione"
            placeholder="Nazione"
            required={required}
            {...partecipantsForm.getInputProps(`partecipants.${index}.country`)}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Provincia"
            placeholder="Provincia"
            required={required}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.province`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Città"
            placeholder="Città"
            required={required}
            {...partecipantsForm.getInputProps(`partecipants.${index}.city`)}
          />
        </Grid.Col>

        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="CAP"
            placeholder="CAP"
            required={required}
            {...partecipantsForm.getInputProps(
              `partecipants.${index}.postalCode`,
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 4 }}>
          <TextInput
            label="Indirizzo"
            placeholder="Indirizzo"
            required={required}
            {...partecipantsForm.getInputProps(`partecipants.${index}.address`)}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Textarea
            label="Note / allergie / altre informazioni"
            placeholder="Note / allergie / altre informazioni"
            {...partecipantsForm.getInputProps(`partecipants.${index}.notes`)}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}
