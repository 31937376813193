import parser from 'any-date-parser';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconAlertCircle, IconMinus, IconPlus } from '@tabler/icons-react';

import {
  selectIsCompany,
  selectPartecipants,
  setIsCompany,
  setPartecipants
} from '@slices/checkout.slice';

interface PartecipantsProps {
  onConfirm: () => void;
}

export default function Partecipants({ onConfirm }: PartecipantsProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);
  const isCompany = useSelector(selectIsCompany);

  // ==========================================================================
  // Form
  // ==========================================================================
  const partecipantsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      female: structuredClone(
        partecipants
          .filter((p) => p.gender === 'female')
          .map((p) => ({
            ...p,
            birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
          })),
      ),
      male: structuredClone(
        partecipants
          .filter((p) => p.gender === 'male')
          .map((p) => ({
            ...p,
            birthDate: p.birthDate ? new Date(p.birthDate) : undefined,
          })),
      ),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = (values: typeof partecipantsForm.values) => {
    dispatch(
      setPartecipants([
        ...values.female.map((p) => ({
          ...p,
          birthDate: p.birthDate?.toISOString(),
        })),
        ...values.male.map((p) => ({
          ...p,
          birthDate: p.birthDate?.toISOString(),
        })),
      ]),
    );

    onConfirm();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={partecipantsForm.onSubmit(onSubmit)}
      style={{ height: '100%' }}
    >
      <Stack h="100%">
        <Alert color="red" icon={<IconAlertCircle />} fw="bold">
          Se viaggi con minori di 10 anni contattaci per maggiori informazioni
        </Alert>

        <Group mt="lg" justify="space-between">
          <Text>
            Seleziona il numero di partecipanti ed inserisci le informazioni
            richieste
          </Text>
          <Checkbox
            label="Acquista come agenzia"
            description="Seleziona se sei un'agenzia viaggi"
            checked={isCompany}
            onChange={(e) => {
              dispatch(setIsCompany(e.currentTarget.checked));

              if (e.currentTarget.checked) {
                let index = partecipantsForm
                  .getValues()
                  .female.findIndex((p) => p.isHolder);
                if (index !== -1) {
                  partecipantsForm.setFieldValue(
                    `female.${index}.isHolder`,
                    false,
                  );
                } else {
                  index = partecipantsForm
                    .getValues()
                    .male.findIndex((p) => p.isHolder);
                  if (index !== -1) {
                    partecipantsForm.setFieldValue(
                      `male.${index}.isHolder`,
                      false,
                    );
                  }
                }
              }
            }}
          />
        </Group>

        <Grid gutter="xl" flex={1} mt="lg">
          <Grid.Col span={{ md: 6 }}>
            <Group wrap="nowrap">
              <Text ta="center" fw="bold" size="lg" mr="lg">
                Donne
              </Text>
              <ActionIcon
                onClick={() => {
                  // Delete last added woman
                  partecipantsForm.removeListItem(
                    'female',
                    partecipantsForm.getValues().female.length - 1,
                  );
                }}
                disabled={partecipantsForm.getValues().female.length === 0}
              >
                <IconMinus />
              </ActionIcon>
              <Text>{partecipantsForm.getValues().female.length}</Text>
              <ActionIcon
                onClick={() => {
                  // Add new woman
                  partecipantsForm.insertListItem('female', {
                    key: randomId(),
                    firstName: '',
                    lastName: '',
                    gender: 'female',
                    birthDate: null,
                    isHolder: false,
                  });
                }}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <Stack mt="lg">
              {partecipantsForm.getValues().female.map((p, index) => (
                <PartecipantsInputs
                  key={p.key}
                  index={index}
                  partecipantsForm={partecipantsForm}
                  gender="female"
                  hideHolder={isCompany}
                />
              ))}
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ md: 6 }}>
            <Group wrap="nowrap">
              <Text ta="center" fw="bold" size="lg" mr="lg">
                Uomini
              </Text>
              <ActionIcon
                onClick={() => {
                  // Delete last added man
                  partecipantsForm.removeListItem(
                    'male',
                    partecipantsForm.getValues().female.length - 1,
                  );
                }}
                disabled={partecipantsForm.getValues().male.length === 0}
              >
                <IconMinus />
              </ActionIcon>
              <Text>{partecipantsForm.getValues().male.length}</Text>
              <ActionIcon
                onClick={() => {
                  // Add new man
                  partecipantsForm.insertListItem('male', {
                    key: randomId(),
                    firstName: '',
                    lastName: '',
                    gender: 'male',
                    birthDate: null,
                    isHolder: false,
                  });
                }}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <Stack mt="lg">
              {partecipantsForm.getValues().male.map((p, index) => (
                <PartecipantsInputs
                  key={p.key}
                  index={index}
                  partecipantsForm={partecipantsForm}
                  gender="male"
                  hideHolder={isCompany}
                />
              ))}
            </Stack>
          </Grid.Col>
        </Grid>

        <Group justify="end" align="end">
          {partecipantsForm.getValues().female.length +
            partecipantsForm.getValues().male.length ===
          0 ? (
            <Alert color="red">
              Seleziona almeno un partecipante per proseguire
            </Alert>
          ) : (
            !isCompany &&
            !partecipantsForm.getValues().female.find((p) => p.isHolder) &&
            !partecipantsForm.getValues().male.find((p) => p.isHolder) && (
              <Alert color="red">
                Seleziona l'intestatario della prenotazione per proseguire
              </Alert>
            )
          )}
          <Button
            size="lg"
            disabled={
              partecipantsForm.getValues().female.length +
                partecipantsForm.getValues().male.length ===
                0 ||
              (!isCompany &&
                !partecipantsForm.getValues().female.find((p) => p.isHolder) &&
                !partecipantsForm.getValues().male.find((p) => p.isHolder))
            }
            type="submit"
          >
            PROSEGUI
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

function PartecipantsInputs({
  index,
  partecipantsForm,
  gender,
  hideHolder,
}: {
  index: number;
  partecipantsForm: UseFormReturnType<any>;
  gender: 'female' | 'male';
  hideHolder: boolean;
}) {
  const { i18n } = useTranslation();

  return (
    <>
      {index !== 0 && <Divider />}
      <Group>
        <TextInput
          label="Nome"
          placeholder="Nome"
          required
          key={partecipantsForm.key(`${gender}.${index}.firstName`)}
          {...partecipantsForm.getInputProps(`${gender}.${index}.firstName`)}
          flex={1}
        />
        <TextInput
          label="Cognome"
          placeholder="Cognome"
          required
          key={partecipantsForm.key(`${gender}.${index}.lastName`)}
          {...partecipantsForm.getInputProps(`${gender}.${index}.lastName`)}
          flex={1}
        />
      </Group>
      <Group align="end">
        <DateInput
          label="Data di nascita"
          placeholder="Data di nascita"
          description="Obbligatoria in caso di polizza"
          {...partecipantsForm.getInputProps(`${gender}.${index}.birthDate`)}
          dateParser={(value) => parser.fromString(value, i18n.language)}
          valueFormat="DD/MM/YYYY"
          flex={1}
        />
        {!hideHolder ? (
          <Checkbox
            label="Intestatario prenotazione"
            description="Seleziona se il partecipante è l'intestatario della prenotazione"
            checked={partecipantsForm.getValues()[gender][index].isHolder}
            onChange={(event) => {
              // Deselect other holders
              if (event.currentTarget.checked) {
                partecipantsForm
                  .getValues()
                  .female.forEach((_: never, i: number) => {
                    partecipantsForm.setFieldValue(
                      `female.${i}.isHolder`,
                      false,
                    );
                  });
                partecipantsForm
                  .getValues()
                  .male.forEach((_: never, i: number) => {
                    partecipantsForm.setFieldValue(`male.${i}.isHolder`, false);
                  });
              }

              partecipantsForm.setFieldValue(
                `${gender}.${index}.isHolder`,
                event.currentTarget.checked,
              );
            }}
            flex={1}
          />
        ) : (
          <Flex flex={1} />
        )}
      </Group>
    </>
  );
}
